.faqItem {
  margin-bottom: 1rem;
}

.faqItem:last-child {
  margin-bottom: 0;
}

.faqItem > p {
  padding: 1.2rem 3rem 1.2rem 2rem;
  border-radius: 0.6rem;

  border: 1px solid rgb(185, 185, 185);
  cursor: pointer;
  transition: 0.1s;
  position: relative;
}

.faqItem > p.active {
  color: #fff;
  background-color: rgb(39, 123, 234);
}

.caretDown {
  position: absolute;
  right: 0;
  margin: 0 1rem;
  transition: 0.1s;
  top: 50%;
  transform: translateY(-50%);
}

.caretDown.active {
  transform: translateY(-50%) rotate(-180deg);
}

.answer {
  height: 0;
  overflow: hidden;
  width: 95%;
  background-color: #caddf6;
  padding: 0 2rem;

  border-radius: 0 0 0.6rem 0.6rem;
  margin: 0 auto;
  transition-duration: 0.1s;
}

.answer.active {
  height: auto;
  padding: 1.2rem 2rem;
}

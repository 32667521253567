@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500;600;700&display=swap");
/* font-family: 'Mukta', sans-serif; */

/* font size */
html {
  font-size: 100%; /* 16px */
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  overflow-wrap: anywhere;
}

body {
  font-family: "Mukta", sans-serif;
  margin: 0 auto;
}

@media only screen and (min-width: 1440px) {
  html {
    font-size: 118.75%; /* 19px */
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 87.5%; /* 14px */
  }
}

@media only screen and (max-width: 425px) {
  html {
    font-size: 81.25%; /* 13px */
  }
}

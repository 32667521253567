.errorModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
}

.card {
  position: relative;
  padding: 2rem 2.4rem;
  background-color: #fff;
  border-radius: 1.2rem;
  text-align: center;
  max-width: 30%;
}

.warn {
  font-size: 10rem;
}

.card > h1 {
  margin-bottom: 0.6rem;
  font-size: 2rem;
  font-weight: 600;
}

.card > p {
  font-size: 1.2rem;
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.6rem;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .card {
    max-width: 80%;
  }
}

.footer {
  padding: 3rem 2rem;
  background-color: rgb(240, 240, 240);
}

.footerNav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.logoWrapper {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
}

.logoWrapper > img {
  height: 4rem;
}

.logoWrapper > div {
  font-size: 1.5rem;
  font-weight: 600;
}

.logoWrapper > div > span {
  display: block;
}

.footerListWrapper {
  display: flex;
  column-gap: 6rem;
}

.footerList {
  padding: auto 2rem;
}

.footerList > p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.footerList > ul {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.copyright {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 2rem;
    background-color: rgb(240, 240, 240);
  }

  .footerNav {
    flex-direction: column-reverse;
    row-gap: 3rem;
  }

  .footerListWrapper {
    column-gap: 0;
    justify-content: space-between;
  }
}

.features {
  margin-bottom: 8rem;
}

.feature {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 2rem;
  margin-bottom: 4rem;
}

.feature:last-child {
  margin-bottom: 0;
}

.featureTextWrapper > h2 {
  color: rgb(39, 123, 234);
  font-weight: 600;
  margin-bottom: 0.4rem;
}

.featureTextWrapper > h3 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 0.6rem;
}

.catGrid {
  width: 25vw;
  height: 25vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.8rem;
  justify-self: center;
  overflow: hidden;
  border-radius: 0.6rem;
}

.catColumnWrapper {
  position: relative;
}

.catColumn {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.8rem;
  position: absolute;
  width: 100%;
  animation-name: gridMove;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

.animationReverse {
  animation-direction: alternate-reverse;
}

.catColumn > img {
  width: 100%;
  border-radius: 0.6rem;
}

@keyframes gridMove {
  from {
    top: 0%;
  }
  to {
    top: -60%;
  }
}

.catUpload {
  width: 25vw;
  height: 25vw;
  justify-self: center;
  position: relative;
  overflow: hidden;
}

.catUpload > button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: buttonPress;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.catImageWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.catImageWrapper > img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.catImageWrapper > img:nth-child(1) {
  animation-name: uploading-img-1;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.catImageWrapper > img:nth-child(2) {
  animation-name: uploading-img-2;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.catImageWrapper > img:nth-child(3) {
  animation-name: uploading-img-3;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.uploadIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1rem;
  width: 60%;
  border-radius: 25px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid rgb(185, 185, 185);
  animation-name: uploadIndicator;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.uploadIndicator > span {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(39, 123, 234);
  height: 100%;
  width: 50%;
  animation-name: uploadIndicatorProgress;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.catUploaded {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: 25rem;
  color: rgb(39, 123, 234);
  background-color: #fff;
  box-shadow: rgb(50 50 93 / 25%) 0px 0px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  animation-name: uploaded;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.completed {
  margin-right: 0.2rem;
}

.catConnect {
  position: relative;
  width: 25vw;
  height: 25vw;
  justify-self: center;
}

.catCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.catConnect img {
  width: 6vw;
}

.catConnect > img {
  position: absolute;
  animation-name: catHeadAppear;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.catConnect > img:nth-child(1) {
  top: 0;
  left: 0;
}

.catConnect > img:nth-child(2) {
  top: 0;
  right: 0;
}

.catConnect > img:nth-child(3) {
  bottom: 0;
  left: 0;
}

.catConnect > img:nth-child(4) {
  bottom: 0;
  right: 0;
}

.catCenter > span {
  background-color: rgb(39, 123, 234);
  position: absolute;
  width: 5.5vw;
  height: 4px;
  border-radius: 25px;
  animation-name: stickFill;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.catCenter > span:nth-child(1) {
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: rotate(225deg);
}

.catCenter > span:nth-child(2) {
  top: 0;
  right: 0;
  transform-origin: top right;
  transform: rotate(135deg);
}

.catCenter > span:nth-child(3) {
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  transform: rotate(135deg);
}

.catCenter > span:nth-child(4) {
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
  transform: rotate(225deg);
}

@keyframes buttonPress {
  0% {
    top: 50%;
    opacity: 100%;
    background-color: rgb(39, 123, 234);
  }
  18.74% {
    top: 53%;
    opacity: 100%;
    background-color: rgb(32, 104, 199);
  }
  18.75%,
  100% {
    opacity: 0;
  }
}

@keyframes uploading-img-1 {
  0%,
  18.75% {
    opacity: 0;
    top: 100%;
    transform: translate(-50%, 0%);
  }
  18.76% {
    opacity: 100%;
    top: 100%;
  }
  31.25%,
  62.49% {
    opacity: 100%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
  62.50%,
  100% {
    opacity: 0;
  }
}

@keyframes uploading-img-2 {
  0%,
  31.25% {
    opacity: 0;
    top: 100%;
    transform: translate(-50%, 0%);
  }

  31.26% {
    opacity: 100%;
    top: 100%;
  }
  43.75%,
  62.49% {
    opacity: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  62.50%,
  100% {
    opacity: 0;
  }
}

@keyframes uploading-img-3 {
  0%,
  43.75% {
    opacity: 0;
    top: 100%;
    transform: translate(-50%, 0%);
  }
  43.76% {
    opacity: 100%;
    top: 100%;
  }
  56.25%,
  62.49% {
    opacity: 100%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
  62.50%,
  100% {
    opacity: 0;
  }
}

@keyframes uploadIndicator {
  0%,
  62.50% {
    opacity: 0;
  }
  62.51% {
    opacity: 100%;
  }
  75.00%,
  81.24% {
    opacity: 100%;
  }
  81.25%,
  100% {
    opacity: 0;
  }
}

@keyframes uploadIndicatorProgress {
  0%,
  62.50% {
    opacity: 0;
    width: 0%;
  }
  62.51% {
    opacity: 100%;
  }
  75.00%,
  81.24% {
    opacity: 100%;
    width: 100%;
  }
  81.25%,
  100% {
    opacity: 0;
  }
}

@keyframes uploaded {
  0%,
  81.25% {
    opacity: 0;
  }
  81.26%,
  93.75% {
    opacity: 100%;
  }
  93.76%,
  100% {
    opacity: 0%;
  }
}

@keyframes catHeadAppear {
  0%,
  10.00% {
    opacity: 0;
    transform: scale(0);
  }
  10.01% {
    opacity: 1;
    transform: scale(0);
  }
  50.00%,
  100.00% {
    transform: scale(1);
  }
}

@keyframes stickFill {
  0%,
  50.01% {
    width: 0;
  }
  90.00%,
  100.00% {
    width: 5.5vw;
  }
}

@keyframes stickFill2 {
  0%,
  50.01% {
    width: 0;
  }
  90.00%,
  100.00% {
    width: 10vw;
  }
}

@media only screen and (max-width: 900px) {
  .feature {
    grid-template-columns: 1fr;
    margin-bottom: 6rem;
  }

  .catGrid {
    width: 60vw;
    height: 60vw;
  }

  .catUpload {
    width: 60vw;
    height: 60vw;
  }

  .catConnect {
    width: 60vw;
    height: 60vw;
  }

  .catConnect img {
    width: 16vw;
  }

  .catCenter > span {
    width: 10vw;
    height: 3px;
    animation-name: stickFill2;
  }
}

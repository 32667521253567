.gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 0.8rem;
}

.galleryColumn {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.imageContainer {
  overflow: hidden;
  border-radius: 0.6rem;
}

.imageContainer img {
  width: 100%;
  transition: transform 0.5s;
}

@media (hover: hover) {
  .imageContainer img:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }

  .fourthColumn {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .thirdColumn {
    display: none;
  }
}

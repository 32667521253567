.testimonialWrapper {
  position: relative;
  left: -2rem;
  padding: 2rem;
  width: calc(100% + 4rem);
  overflow: hidden;
}

.testimonialSlider {
  display: flex;
  width: 100%;
  column-gap: 5%;
  position: relative;
  left: 35%;
  transition-duration: 1s;
}

.testimonialCard {
  flex: 0 0 30%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  box-shadow: rgb(50 50 93 / 25%) 0px 0px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  border-radius: 0.6rem;
}

.testimonialCard > p {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.testomonialUser {
  margin-top: 2.4rem;
  padding-top: 1.6rem;
  border-top: 0.1rem solid rgb(185, 185, 185);
}

.testomonialUser > img {
  width: 3rem;
  border-radius: 50%;
  display: block;
  margin: 0 auto 0.6rem auto;
}

.testomonialUser > p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: center;
  color: rgb(39, 123, 234);
}

.buttonWrapper {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  column-gap: 2rem;
}

.buttonWrapper > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  border: none;
  border-radius: 0.6rem;
  background-color: rgb(39, 123, 234);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.buttonWrapper > button:hover {
  background-color: rgb(32, 104, 199);
}

@media only screen and (max-width: 900px) {
  .testimonialSlider {
    column-gap: 10%;
  }

  .testimonialCard {
    flex: 0 0 100%;
  }
}

.navbar {
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  z-index: 100;
}

.scrolled {
  box-shadow: 0 0.0625rem 0.625rem 0 rgb(0 0 0 / 20%);
}

.navbarWrapper {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.logoWrapper {
  display: flex;
  align-items: center;
  height: inherit;
}

.logoWrapper > img {
  height: inherit;
}

.logoWrapper > div {
  font-size: 1.5rem;
  font-weight: 600;
}

.logoWrapper > div > span {
  display: block;
}

.navList {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.navProfile > a {
  display: flex;
  align-items: center;
}

.navProfile > a > svg {
  font-size: 1.6rem;
  color: rgb(39, 123, 234);
}

.navPopoverList > li {
  margin-bottom: 0.6rem;
  cursor: pointer;
}

.navPopoverList > li:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .navList {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .navbarWrapper {
    padding: 0 1rem;
  }
}

.displayImage {
  width: 40%;
}

.displayImage > img {
  width: 100%;
  border-radius: 2rem;
}

@media screen and (max-width: 768px) {
  .displayImage {
    width: 100%;
    margin-bottom: 2rem;
  }
}

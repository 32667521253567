.faq {
  margin-bottom: 8rem;
}

.faq > h2 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 2.8rem;
  text-align: center;
}

.editor {
  display: flex;
  flex-direction: column;
}

.editor > textarea {
  width: 100%;
  padding: 0.6rem;
  margin-bottom: 0.4rem;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 0.6rem;
  border: 2px solid rgba(0, 0, 0, 0.2);
  outline: none;
  resize: none;
}

.buttonWrapper {
  align-self: flex-end;
}

.buttonWrapper > button:last-child {
  margin-left: 0.6rem;
}

.button {
  padding: 0.6rem 1rem;
  border: none;
  border-radius: 0.6rem;
  font-size: inherit;
  color: #fff;
  background-color: rgb(39, 123, 234);
  cursor: pointer;
}

.button:hover {
  background-color: rgb(32, 104, 199);
}

.button:disabled {
  background-color: #caddf6;
  color: #000;
}

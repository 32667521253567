.forgotPassword {
  display: flex;
  align-items: center;
  justify-content: center;
}

.successText {
  text-align: center;
  font-size: 1.2rem;
}

.testimonial {
  margin-bottom: 8rem;
}

.testimonial > h2 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  text-align: center;
}

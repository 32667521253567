.loginForm {
  background-color: #fff;
  padding: 2rem 2.4rem;
  border-radius: 1.2rem;
  box-shadow: 2px 10px 23px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 10px 23px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 10px 23px -10px rgba(0, 0, 0, 0.75);
}

.loginForm > h1 {
  font-size: 1.8rem;
}

.extraWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 2rem;
  margin-top: 1.2rem;
}

.loginForm a {
  color: rgb(39, 123, 234);
}

.loginForm a:hover {
  color: rgb(32, 104, 199);
}

@media only screen and (max-width: 768px) {
  .loginForm {
    width: 100%;
  }
}

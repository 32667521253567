.forgotPasswordForm {
  background-color: #fff;
  padding: 2rem 2.4rem;
  border-radius: 1.2rem;
  box-shadow: 2px 10px 23px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 10px 23px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 10px 23px -10px rgba(0, 0, 0, 0.75);
}

.forgotPasswordForm > h1 {
  font-size: 1.8rem;
}

.nameWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
}

.extraWrapper {
  margin-top: 1.2rem;
}

.forgotPasswordForm a {
  color: rgb(39, 123, 234);
}

.forgotPasswordForm a:hover {
  color: rgb(32, 104, 199);
}

@media only screen and (max-width: 768px) {
  .forgotPasswordForm {
    width: 100%;
  }

  .nameWrapper {
    flex-direction: column;
  }
}

.loginModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
}

.card {
  position: relative;
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.6rem;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .card {
    max-width: 80%;
  }
}

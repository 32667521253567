.hero {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  margin-bottom: 8rem;
}

.heroHeadline {
  padding: 2rem 0;
}

.heroHeadline > h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1.6rem;
  position: relative;
}

.heroHeadline > h1 > img {
  position: absolute;
  width: 4.6rem;
  user-select: none;
}

.heroHeadline > p {
  max-width: 80%;
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-bottom: 1.6rem;
}

.heroStatsWrapper {
  display: flex;
}

.heroStatsWrapper > p:first-child {
  padding-right: 2rem;
}

.heroStatsWrapper > p:last-child {
  padding-left: 2rem;
}

.heroStatsWrapper > p > span {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.6rem;
}

.statsSeparator {
  width: 0.1rem;
  background-color: rgb(185, 185, 185);
}

.heroImg {
  display: flex;
  align-items: center;
}

.heroImgBg {
  background-color: rgb(227, 53, 79);
  width: 45%;
  border-radius: 25rem 0 0 25rem;
  height: 20rem;
  position: absolute;
  right: 0;
}

.heroImgBg > img {
  position: absolute;
  bottom: 0;
  height: 26rem;
  left: 5.4rem;
  user-select: none;
}

.heroReview {
  background-color: #fff;
  display: inline-block;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  position: absolute;
  left: 16rem;
  bottom: 4rem;
  padding: 1rem;
  border-radius: 0.6rem;
  width: 12rem;
}

.heroReview > p {
  font-weight: 600;
}

.heroReviewProfile {
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-bottom: 0.6rem;
  padding-bottom: 0.4rem;
  border-bottom: 0.1rem solid rgb(185, 185, 185);
}

.heroReviewProfile > img {
  width: 2.6rem;
  border-radius: 50%;
  margin-right: 0.4rem;
}

.heroReviewUser {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.heroReviewUser > p {
  font-weight: 500;
}

.heroReviewRating {
  font-size: 1.2rem;
  color: rgb(255, 200, 48);
}

.heroBest {
  position: absolute;
  left: 20rem;
  top: -1.6rem;
  border-radius: 25rem;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 1rem;
}

.heroBest > p {
  color: rgb(39, 123, 234);
  display: flex;
}

.verified {
  margin-right: 0.2rem;
}

.likesWrapper {
  position: absolute;
  top: -2.8rem;
  right: -1.4rem;
  transform: rotate(25deg);
}

.likesWrapper::after {
  height: 0.8rem;
  width: 0.8rem;
  content: "";
  background-color: rgb(255, 243, 25);
  position: absolute;
  left: 50%;
  bottom: -0.1rem;
  transform: translateX(-50%) rotate(45deg);
}

.likes {
  padding: 0.6rem;
  background-color: rgb(255, 243, 25);
  border-radius: 0.5rem;
  box-sizing: content-box;
}

.likes > path {
  fill: #fff;
}

@media screen and (max-width: 1170px) {
  .hero {
    grid-template-columns: 1fr;
  }

  .heroImg {
    display: block;
    position: relative;
    left: -2rem;
    padding-top: 10rem;
  }

  .heroImgBg {
    position: relative;
    top: 0;
    width: calc(100% + 4rem);
    height: 16rem;
    border-radius: 0;
  }

  .heroImgBg > img {
    left: 45%;
    transform: translateX(-50%);
  }

  .heroReview {
    left: 50%;
  }

  .heroBest {
    left: 55%;
  }
}

@media screen and (max-width: 425px) {
  .heroReview {
    left: 25%;
    bottom: 2rem;
  }

  .heroBest {
    left: 40%;
    top: 1.8rem;
  }
}

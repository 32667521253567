.mobileNav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}

.navList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;

  font-size: 2rem;
  font-weight: 500;
}

.navList > li {
  cursor: pointer;
}

.burgerButton {
  display: none;
  margin-left: auto;
  font-size: 1.8rem;
  z-index: 1;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .active {
    visibility: visible;
    opacity: 1;
  }

  .burgerButton {
    display: block;
  }
}
